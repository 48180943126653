<template>
    <div class="p-grid">
        <div class="p-col-12">
            <!-- <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-mb-4">
                <span>
                    <Button type="button" label="Candidate Dashboard" @click="redirectToCandidateDashboard" class="p-button-outlined p-component p-mr-2" />
                    <Button type="button" label="Employer Dashboard" @click="redirectToEmployerDashboard" class="p-button p-component p-mr-2" />
                </span>
            </div> -->
            <div class="p-grid dashboard">
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="card overview-box p-d-flex p-flex-column p-pt-2 green-bgcolor solid-surface-text-color">
                        <div class="p-d-flex p-ai-center p-py-3">
                            <i class="layout-menuitem-icon pi pi-fw pi-check-circle"></i>
                            <h6 class="p-m-0" :class="{ 'p-pl-2': !isRTL, 'p-pr-2': isRTL }">Today</h6>
                        </div>
                        <div class="p-d-flex">
                            <span class="p-mb-1 fs-xlarge">640</span>
                        </div>
                        <div class="p-d-flex p-ai-end">
                            <Chart ref="overviewChartData1" type="line" :data="overviewChartData1"
                                :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="card overview-box p-d-flex p-flex-column p-pt-2 orange-bgcolor solid-surface-text-color">
                        <div class="p-d-flex p-ai-center p-py-3">
                            <i class="layout-menuitem-icon pi pi-fw pi-exclamation-triangle"></i>
                            <h6 class="p-m-0" :class="{ 'p-pl-2': !isRTL, 'p-pr-2': isRTL }">Pending</h6>
                        </div>
                        <div class="p-d-flex">
                            <span class="p-mb-1 fs-xlarge">571</span>
                        </div>
                        <div class="p-d-flex p-ai-end">
                            <Chart ref="overviewChartData2" type="line" :data="overviewChartData2"
                                :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="card overview-box p-d-flex p-flex-column p-pt-2 purple-bgcolor solid-surface-text-color">
                        <div class="p-d-flex p-ai-center p-py-3">
                            <i class="layout-menuitem-icon pi pi-fw pi-th-large"></i>
                            <h6 class="p-m-0" :class="{ 'p-pl-2': !isRTL, 'p-pr-2': isRTL }">Total</h6>
                        </div>
                        <div class="p-d-flex">
                            <span class="p-mb-1 fs-xlarge">8572</span>
                        </div>
                        <div class="p-d-flex p-ai-end">
                            <Chart ref="overviewChartData3" type="line" :data="overviewChartData3"
                                :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="card overview-box p-d-flex p-flex-column p-pt-2 pink-bgcolor solid-surface-text-color">
                        <div class="p-d-flex p-ai-center p-py-3">
                            <i class="layout-menuitem-icon pi pi-fw pi-clone"></i>
                            <h6 class="p-m-0" :class="{ 'p-pl-2': !isRTL, 'p-pr-2': isRTL }">Total Hosts</h6>
                        </div>
                        <div class="p-d-flex">
                            <span class="p-mb-1 fs-xlarge">805</span>
                        </div>
                        <div class="p-d-flex p-ai-end">
                            <Chart ref="overviewChartData4" type="line" :data="overviewChartData4"
                                :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-lg-12 p-pt-3">
                    <div class="p-grid">
                        <div class="p-col-12 p-lg-4 p-d-flex p-ai-center">
                            <i class="pi pi-book p-p-2 cyan-bgcolor white-color rounded-circle"></i>
                            <span class="p-ml-2 muted-text">No. of Visitors : N/A</span>
                            <span class="fs-xlarge p-ml-1 cyan-color p-text-bold">{{ dashboard_count.jobPostCount }}</span>
                        </div>
                        <div class="p-col-12 p-lg-4 p-d-flex p-ai-center">
                            <i class="pi pi-bookmark p-p-2 cyan-bgcolor white-color rounded-circle"></i>
                            <span class="p-ml-2 muted-text">No. of Admin : N/A</span>
                            <span class="fs-xlarge p-ml-1 cyan-color p-text-bold">{{ dashboard_count.employerCount }}</span>
                        </div>
                        <div class="p-col-12 p-lg-4 p-d-flex p-ai-center">
                            <i class="pi pi-chart-line p-p-2 cyan-bgcolor white-color rounded-circle"></i>
                            <span class="p-ml-2 muted-text">Total No. of Hosts  : N/A</span>
                            <span class="fs-xlarge p-ml-1 cyan-color p-text-bold">{{ dashboard_count.totalOpening }}</span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-6">
                    <div class="card height-100">
                        <div class="card-header">
                            <h5>Todays Visitors</h5>
                            <!-- <div>
                                <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain" @click="redirectToCandidateFollowUps"></Button>
                            </div> -->
                        </div>

                        <ul class="widget-list">
                            <li class="p-d-flex p-ai-center p-py-3">
                                <div class="person-item p-d-flex p-ai-center">
                                    <img src="assets/layout/images/widgets/asset-profile-11.png" width="100" height="100"
                                        style="vertical-align: middle; border-radius: 50%; border: 2px solid #ecebeb" />
                                    <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                        <div class="p-text-capitalize p-mb-1">Goa Electronics<span class="p-px-2">|</span>Radisson Blu Resort<span class="p-px-2">|</span>Cavelossim Beach Goa<span
                                                class="p-px-2">|</span>Raju Sharma</div>
                                        <small class="muted-text">
                                            <Button icon="pi pi-phone"
                                                class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                style="vertical-align: middle" />+91 9999999999
                                            <span class="p-px-2">|</span>
                                            <span size="{10}"><Button icon="pi pi-phone"
                                                    class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                    style="vertical-align: middle" />+91 9999999999</span>
                                        </small>
                                    </div>
                                </div>
                            </li>

                            <li class="p-d-flex p-ai-center p-py-3">
                                <div class="person-item p-d-flex p-ai-center">
                                    <img src="assets/layout/images/widgets/asset-profile-11.png" width="100" height="100"
                                        style="vertical-align: middle; border-radius: 50%; border: 2px solid #ecebeb" />
                                    <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                        <div class="p-text-capitalize p-mb-1">Goa Electronics<span class="p-px-2">|</span>Radisson Blu Resort<span class="p-px-2">|</span>Cavelossim Beach Goa<span
                                                class="p-px-2">|</span>Raju Sharma</div>
                                        <small class="muted-text">
                                            <Button icon="pi pi-phone"
                                                class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                style="vertical-align: middle" />+91 9999999999
                                            <span class="p-px-2">|</span>
                                            <span size="{10}"><Button icon="pi pi-phone"
                                                    class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                    style="vertical-align: middle" />+91 9999999999</span>
                                        </small>
                                    </div>
                                </div>
                            </li>

                            <li class="p-d-flex p-ai-center p-py-3">
                                <div class="person-item p-d-flex p-ai-center">
                                    <img src="assets/layout/images/widgets/asset-profile-11.png" width="100" height="100"
                                        style="vertical-align: middle; border-radius: 50%; border: 2px solid #ecebeb" />
                                    <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                        <div class="p-text-capitalize p-mb-1">Goa Electronics<span class="p-px-2">|</span>Radisson Blu Resort<span class="p-px-2">|</span>Cavelossim Beach Goa<span
                                                class="p-px-2">|</span>Raju Sharma</div>
                                        <small class="muted-text">
                                            <Button icon="pi pi-phone"
                                                class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                style="vertical-align: middle" />+91 9999999999
                                            <span class="p-px-2">|</span>
                                            <span size="{10}"><Button icon="pi pi-phone"
                                                    class="p-button-rounded p-button-sm p-button-xs p-button-success p-mr-1"
                                                    style="vertical-align: middle" />+91 9999999999</span>
                                        </small>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="p-col-12 p-lg-6">
                    <div class="card height-100">
                        <div class="card-header">
                            <h5>Visitors History</h5>
                        </div>
                        <Chart type="line" :data="ordersChart" :options="ordersOptions"></Chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import ApiService from '../service/ApiService';
//import router from '@/router';

export default {
    data() {
        return {
            overviewChartData1: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                datasets: [
                    {
                        data: [50, 64, 32, 24, 18, 27, 20],
                        borderColor: ['#4DD0E1'],
                        backgroundColor: ['rgba(77, 208, 225, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            },
            overviewChartData2: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                datasets: [
                    {
                        data: [11, 30, 52, 35, 39, 20, 14],
                        borderColor: ['#4DD0E1'],
                        backgroundColor: ['rgba(77, 208, 225, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            },
            overviewChartData3: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                datasets: [
                    {
                        data: [20, 29, 39, 36, 45, 24, 28],
                        borderColor: ['#4DD0E1'],
                        backgroundColor: ['rgba(77, 208, 225, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            },
            overviewChartData4: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                datasets: [
                    {
                        data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
                        borderColor: ['#4DD0E1'],
                        backgroundColor: ['rgba(77, 208, 225, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            },
            overviewChartOptions: {
                legend: {
                    display: false,
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            display: false,
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
            ordersChart: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                datasets: [
                    {
                        label: 'Indian Visitors',
                        data: [31, 83, 69, 29, 62, 25, 59],
                        borderColor: ['#4DD0E1'],
                        backgroundColor: ['rgba(77, 208, 225, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                    {
                        label: 'Foreign Visitors',
                        data: [67, 98, 27, 88, 38, 3, 22],
                        borderColor: ['#3F51B5'],
                        backgroundColor: ['rgba(63, 81, 181, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            },
            ordersOptions: null,
            dashboard_count: '',
        };
    },
    ApiService: null,
    created() {
        //this.ApiService = new ApiService();
    },
    mounted() {
        this.refreshChart();
        //this.getemployerdashboardcount();
    },
    watch: {
        '$appState.isNewThemeLoaded'(isLoaded) {
            if (isLoaded) {
                this.refreshChart();
                this.$appState.isNewThemeLoaded = false;
            }
        },
    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
    },
    methods: {
        refreshChart() {
            this.ordersOptions = this.getOrdersOptions();
            this.setOverviewColors();
        },
        getOrdersOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                legend: {
                    display: true,
                    labels: {
                        fontFamily,
                        fontColor: textColor,
                    },
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                fontFamily,
                                fontColor: textColor,
                            },
                            gridLines: {
                                color: gridLinesColor,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontFamily,
                                fontColor: textColor,
                            },
                            gridLines: {
                                color: gridLinesColor,
                            },
                        },
                    ],
                },
            };
        },
        setOverviewColors() {
            const { whiteBgColor, whiteBorderColor } = this.getOverviewColors();

            this.overviewChartData1.datasets[0].borderColor[0] = whiteBorderColor;
            this.overviewChartData1.datasets[0].backgroundColor[0] = whiteBgColor;

            this.overviewChartData2.datasets[0].borderColor[0] = whiteBorderColor;
            this.overviewChartData2.datasets[0].backgroundColor[0] = whiteBgColor;

            this.overviewChartData3.datasets[0].borderColor[0] = whiteBorderColor;
            this.overviewChartData3.datasets[0].backgroundColor[0] = whiteBgColor;

            this.overviewChartData4.datasets[0].borderColor[0] = whiteBorderColor;
            this.overviewChartData4.datasets[0].backgroundColor[0] = whiteBgColor;

            this.$refs.overviewChartData1.reinit();
            this.$refs.overviewChartData2.reinit();
            this.$refs.overviewChartData3.reinit();
            this.$refs.overviewChartData4.reinit();
        },
        getOverviewColors() {
            const isLight = this.$appState.layoutMode === 'light';
            return {
                whiteBorderColor: isLight ? '#ffffff' : '#ffffff',
                whiteBgColor: isLight ? 'rgba(255,255,255,.35)' : 'rgba(255,255,255,.35)',
            };
        },
        getemployerdashboardcount() {
            this.ApiService.getemployerdashboardcount().then((data) => {
                if (data.status === 200) {
                    this.dashboard_count = data.data;
                }
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}</style>
